import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  viewImage(imageUrl: string) {
    return Swal.fire({
      imageUrl,
      imageHeight: 1500,
      imageAlt: 'A tall image',
    });
  }

  async confirmAction(
    message: string,
    title?: string,
    icono?: string,
    showCancelButton?: boolean,
    confirmButtonText?: string,
    cancelButtonText?: string,
    html?: string
  ) {
    return await Swal.fire({
      title: title ?? '',
      text: message ?? '',
      icon: (icono ?? 'info') as SweetAlertIcon,
      showCancelButton: showCancelButton ?? true,
      confirmButtonColor: '#70BE01',
      cancelButtonColor: '#5E5E5E',
      confirmButtonText: confirmButtonText ?? 'Aceptar',
      cancelButtonText: cancelButtonText ?? 'Cancelar',
      allowOutsideClick: true,
      html,
    });
  }

  success(message: string, html: string = '') {
    return Swal.fire({
      text: message,
      icon: 'success',
      allowOutsideClick: false,
      confirmButtonColor: '#70BE01',
      html,
    });
  }

  error(message: string, html: string = '') {
    return Swal.fire({
      text: message,
      icon: 'error',
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#70BE01',
      html,
    });
  }

  customError(html: string, title: string, icon: SweetAlertIcon) {
    return Swal.fire({
      title,
      icon,
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#70BE01',
      html,
    });
  }

  warning(message: string, title = '') {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#70BE01',
    });
  }

  info(message: string, title = '') {
    return Swal.fire({
      text: message,
      title: title,
      icon: 'info',
      allowOutsideClick: false,
      confirmButtonColor: '#70BE01',
    });
  }

  personalizeTextAreaAlert(
    title: string,
    inputLabel: string,
    inputPlaceholder: string,
    confirmButtonText: string,
    messageValidationInput?: string
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      allowOutsideClick: false,
      title,
      input: 'textarea',
      inputLabel,
      inputPlaceholder,
      inputAttributes: {
        'aria-label': inputPlaceholder,
        autocapitalize: 'off',
      },
      confirmButtonText,
      confirmButtonColor: '#70BE01',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#5E5E5E',
      inputValidator: (value) => {
        if (!value) {
          return messageValidationInput ?? 'CAMPO OBLIGATORIO';
        }
        return null;
      },
    });
  }

  alertViewImg(imageUrl: string) {
    return Swal.fire({
      imageUrl,
      width: 800,
      imageWidth: 650,
      imageHeight: 450,
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: false,
    });
  }

  alertTimer(message: string, timer: number) {
    let timerInterval;
    Swal.fire({
      title: 'Auto close alert!',
      html: 'I will close in <b></b> milliseconds.',
      timer: 10000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector('b');
        timerInterval = setInterval(() => {
          timer.textContent = `${Swal.getTimerLeft()}`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer');
      }
    });
  }
}
